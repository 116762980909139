import * as RadixToast from '@radix-ui/react-toast';
import { type ReactNode, useMemo, useReducer } from 'react';
import { Toast, type ToastProps } from '~/components/granular';
import { contextFactory } from '~/utils/contextFactory';

enum ToastActionType {
  ADD_TOAST = 'ADD_TOAST',
  REMOVE_TOAST = 'REMOVE_TOAST',
}

interface ToastAction {
  type: ToastActionType;
  payload: ToastProps;
}

interface ToastReducerState {
  toasts: Array<ToastProps>;
}

interface ToastContextProps {
  addToast: ({ copyText, appearance, description, title, onOpenChange }: ToastProps) => void;
  removeToast: ({ id }: ToastProps) => void;
}

export const [ToastContext, useToastContext] = contextFactory<ToastContextProps>();

export const toastReducer = (state: ToastReducerState, action: ToastAction) => {
  switch (action.type) {
    case ToastActionType.ADD_TOAST:
      return {
        ...state,
        toasts: [...state.toasts, action.payload],
      };
    case ToastActionType.REMOVE_TOAST:
      return {
        ...state,
        toasts: state.toasts.filter((toast) => toast.id !== action.payload.id),
      };
    default:
      throw new Error();
  }
};

const initialState = { toasts: [] };

export const ToastContextProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(toastReducer, initialState);

  const value = useMemo(
    () => ({
      addToast: ({ id, copyText, appearance, description, title, onOpenChange }: ToastProps) => {
        dispatch({
          type: ToastActionType.ADD_TOAST,
          payload: { id, copyText, appearance, description, title, onOpenChange },
        });
      },

      removeToast: ({ id }: ToastProps) => {
        dispatch({ type: ToastActionType.REMOVE_TOAST, payload: { id } });
      },
    }),
    [],
  );

  return (
    <RadixToast.Provider swipeDirection="down">
      <ToastContext.Provider value={value}>
        {children}
        {state.toasts.map((toast) => (
          <Toast
            key={`${toast.id}-${crypto.randomUUID()}`}
            id={toast.id}
            title={toast.title}
            description={toast.description}
            appearance={toast.appearance}
            copyText={toast.copyText}
            onOpenChange={(state) => !state && value.removeToast({ id: toast.id })}
          />
        ))}
      </ToastContext.Provider>
    </RadixToast.Provider>
  );
};
