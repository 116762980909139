import * as RadixToast from '@radix-ui/react-toast';
import { useCopyToClipboard } from 'usehooks-ts';

import { Icon } from '~/components/granular';
import { classNames } from '~/utils/styles';
import type { ToastProps } from './toast.types';

export function Toast({ open, title, description, copyText, appearance = 'success', onOpenChange }: ToastProps) {
  const [_copiedText, copy] = useCopyToClipboard();

  const handleCopy = (text: string) => () => {
    copy(text).finally(() => {
      if (typeof onOpenChange === 'function') onOpenChange(false);
    });
  };

  const className = classNames(
    "grid grid-cols-[auto_max-content] items-center p-3 text-white shadow-md [grid-template-areas:_'title_action_close'_'description_action_close'] data-[swipe=cancel]:translate-y-0 data-[swipe=move]:translate-y-[var(--radix-toast-swipe-move-y)] data-[state=closed]:animate-hide data-[state=open]:animate-slideIn data-[swipe=end]:animate-swipeOut data-[swipe=cancel]:transition-[transform_200ms_ease-out]",
    { 'bg-teal-700': appearance === 'success' },
    { 'bg-red-700': appearance === 'error' },
  );

  return (
    <RadixToast.Root className={className} open={open} onOpenChange={onOpenChange}>
      {title ? (
        <RadixToast.Title className="body-250-heavy mb-[5px] [grid-area:_title]">{title}</RadixToast.Title>
      ) : null}

      <RadixToast.Description asChild>
        <p className="body-250-light [grid-area:_description]">{description}</p>
      </RadixToast.Description>

      {copyText ? (
        <RadixToast.Action asChild className="[grid-area:_action]" altText="Copy">
          <button type="button" aria-label="Copy" onClick={handleCopy(copyText)}>
            <Icon icon="CopyIcon" height={16} width={16} />
          </button>
        </RadixToast.Action>
      ) : null}

      <RadixToast.Close aria-label="Close" className="flex justify-end [grid-area:close]">
        <Icon icon="CloseIcon" />
      </RadixToast.Close>
    </RadixToast.Root>
  );
}

export function ToastViewport() {
  return (
    <RadixToast.Viewport className="fixed right-0 bottom-0 left-0 z-[2147483647] m-0 mx-auto flex w-[450px] max-w-[100vw] list-none flex-col gap-[10px] p-[var(--viewport-padding)] outline-none [--viewport-padding:_16px]" />
  );
}
